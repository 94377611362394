import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppSetting } from '@lbmx/types';
import { BehaviorSubject, Observable, of, scheduled, Subject } from 'rxjs';
import { catchError, exhaustMap, map, takeUntil, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { IAnalyticsAuth } from '../analytics/analytics.service';

export interface IPortalTemplate {
  toAddress: string;
  subject: string;
  emailText: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavBarService implements OnDestroy {
  private _shouldDestroy = new Subject();
  private showMarketplaceModalBehaviorSubject = new BehaviorSubject<number>(0);
  public showMarketplaceModal =
    this.showMarketplaceModalBehaviorSubject.asObservable();
  private showPortalModalBehaviorSubject = new BehaviorSubject<number>(0);
  public showPortalModal =
    this.showPortalModalBehaviorSubject.asObservable();
  public appSettings: AppSetting = this.configPrv.AppSetting;

  constructor(
    private configPrv: ConfigProvider,
    private router: Router,
    private httpClient: HttpClient
  ) {}

  public getPortalTemplate(): Observable<IPortalTemplate> {
    return this.httpClient
      .get(`${this.appSettings.uriConfiguration.configurationRootURL}/navbar/getPortalTemplate`, { withCredentials: true })
      .pipe(
        map((res: any) => (res.record)),
        catchError((error) => of(error))
      );
  }

  public getLoginUrls(): Observable<any> {
    return this.httpClient
      .get(this.appSettings.uriSecurity.analytics, { withCredentials: true })
      .pipe(
        exhaustMap((result: IAnalyticsAuth) => {
          return this.httpClient
            .get(result.loginURL, {
              withCredentials: true,
              responseType: 'text',
              headers: new HttpHeaders({
                'Content-type': 'text/html; charset=utf-8',
              }),
            })
            .pipe(
              map(() => result.redirectURL),
              catchError((error) => of(error))
            );
        })
      );
  }

  public getPortalUrls(args: string[]): Observable<any> {
    return this.httpClient
      .get(this.appSettings.uriSecurity.portal, { withCredentials: true, params: { site: args[0], toolKey: args[1] } })
      .pipe(
        map((result: IAnalyticsAuth) => result.loginURL),
        catchError((error) => of(error))
      );
  }

  public runCommand(command: any) {
    if (command) {
      const key: string = Object.keys(command)[0];
      this.dispatcher(key, command[key]);
    }
  }

  public dispatcher(key: string, args: string[]) {
    switch (key) {
      case 'navigate':
        this.router.navigate(args);
        break;
      case 'switchMarketplace':
        this.switchMarketplace();
        break;
      case 'openAnalytics':
        this.openAnalytics();
        break;
      case 'openPortal':
        this.openPortal(args);
        break;
      default:
      break;
    }
  }

  public switchMarketplace() {
    this.showMarketplaceModalBehaviorSubject.next(Date.now());
  }

  private openAnalytics() {
    this.getLoginUrls()
      .pipe(
        tap((result: string) => {
          if (result.match(/^http/)) {
            window.open(result);
          }
        }),
        takeUntil(this._shouldDestroy)
      )
      .subscribe();
  }

  private openPortal(args: string[]) {
    this.getPortalUrls(args)
      .pipe(
        tap((result: string) => {
          if (result.match(/^http/)) {
            window.open(result);
          }
        }),
        catchError(() => of(this.showPortalError())),
        takeUntil(this._shouldDestroy)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._shouldDestroy.next(true);
  }

  public showPortalError() {
    this.showPortalModalBehaviorSubject.next(Date.now());
  }
}
