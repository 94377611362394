import { Component, OnInit } from '@angular/core';
import { selectors } from '@lbmx/app-state';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import {
  BaseHttpService,
  SearchContainerLoaderService,
} from '@lbmx/search-container';
import { toolKeys } from '@lbmx/types';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { ConfirmationService } from 'primeng-lts';
import { of, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

enum DownloadType {
  Single = 1,
  Multiple,
}
@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  providers: [SearchContainerLoaderService, ConfirmationService],
})
export class ExportsComponent implements OnInit {
  // search container loader states
  public searchContainerName = 'export';
  public i18nSearchField = 'EXPORT.SEARCH_BAR';
  public toolbarActionsDispatcher = {
    delete: (selection: any[]) => {
      this.confirmationService.confirm({
        message: this.translocoService.translate(
          'VALIDATION_MESSAGE.CONFIRMATION_MESSAGE'
        ),
        header: this.translocoService.translate(
          'VALIDATION_MESSAGE.CONFIRMATION_HEADER'
        ),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.spinner.on();
          this.http
            .fetch({
              endpoint: 'delete',
              httpMethod: 'POST',
              body: {
                keys: [...selection].map(({ exportKey, ..._ }) => exportKey),
              },
            })
            .pipe(
              tap(() => {
                this.toast.successNotify(
                  this.translocoService.translate('GROUP.SUCCESS_TOAST_MESSAGE')
                );
                this.searchContainerLoaderService.refreshSearch();
              }),
              catchError(() =>
                of(
                  this.toast.errorNotify(
                    this.translocoService.translate('MESSAGES.GENERIC_ERROR')
                  )
                )
              ),
              finalize(() => this.spinner.off())
            )
            .subscribe();
        },
        // tslint:disable-next-line: no-empty
        reject: () => {},
      });
    },
    download: (selection: any[]) => {
      this.downloadMultipleFile(
        [...selection].map(({ exportKey, ..._ }) => exportKey)
      );
    },
  };
  public baseUrl = `${this.configPrv.AppSetting?.uriAdministration?.getExportRootURL}`;
  public columnActions = {};
  private subscriptions: Subscription[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private toast: ToastrNotificationService,
    private spinner: SpinnerService,
    private configPrv: ConfigProvider,
    private http: BaseHttpService,
    private searchContainerLoaderService: SearchContainerLoaderService,
    private store: Store<any>
  ) {
    http.baseUrl =
      this.configPrv.AppSetting?.uriAdministration?.getExportRootURL;
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.isAuthorized, {
            export: toolKeys.AUTHENTICATED,
          }),
          tap((authorizedButtons: { export: boolean }) => {
            this.columnActions = {
              fileName: authorizedButtons.export
                ? {
                    link: () => ({ path: './' }),
                    event: (exportRecord: { [key: string]: any }) =>
                      exportRecord,
                  }
                : {},
            };
          })
        )
        .subscribe()
    );
  }

  public downloadFile(data: any) {
    const fileName = data?.fileName;
    const exportKey = data?.exportKey;
    this._download([exportKey], DownloadType.Single, fileName);
  }
  public downloadMultipleFile(exportKeys: number[]) {
    this._download(exportKeys, DownloadType.Multiple);
  }

  private _download(
    exportKeys: number[],
    downloadType: DownloadType,
    fileName?: string
  ) {
    this.spinner.on();
    this.http
      .fetch({
        endpoint: 'download',
        httpMethod: 'POST',
        body: {
          keys: exportKeys,
        },
        options: { responseType: 'blob' },
      })
      .pipe(
        tap((response: Blob) => {
          saveAs(
            response,
            downloadType === 1 ? `${fileName}` : 'MergedDocuments.zip'
          );
          this.toast.successNotify(
            this.translocoService.translate('GROUP.SUCCESS_TOAST_MESSAGE')
          );
          this.searchContainerLoaderService.refreshSearch();
        }),
        catchError(() =>
          of(
            this.toast.errorNotify(
              this.translocoService.translate('MESSAGES.GENERIC_ERROR')
            )
          )
        ),
        finalize(() => this.spinner.off())
      )
      .subscribe();
  }
}
