import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerFormPrimengModule } from '@lbmx/per-form-primeng';
import { TranslocoModule } from '@ngneat/transloco';
import { ProgressSpinnerModule, TreeModule } from 'primeng';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { DefaultPaginatorModule } from '../../lui-legacy-components/default-paginator/default-paginator.module';
import { LegacySearchContainerModule } from '../../lui-legacy-components/legacy-search-container/module';
import { LuiPaginatorModule } from '../../lui-legacy-components/paginator/paginator.module';
import { TableHeaderModule } from '../../lui-legacy-components/table-header/module';
import { LuiTableModule } from '../../lui-legacy-components/table/module';
import { TreeAppModule } from '../../lui-legacy-components/tree-app/tree-app.module';
import { DemoComponent } from './demo.component';

@NgModule({
  declarations: [DemoComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CheckboxModule,
    DefaultPaginatorModule,
    DragDropModule,
    FormsModule,
    LegacySearchContainerModule,
    LuiPaginatorModule,
    LuiTableModule,
    ProgressSpinnerModule,
    TranslocoModule,
    TreeModule,
    TableHeaderModule,
    TreeAppModule,
    PerFormPrimengModule,
  ],
})
export class DemoModule {}
